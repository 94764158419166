import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/ui/PageTitle';

const faqs = [
     {
          question: 'What kind of equipment will I be renting?',
          answer: 'Ripout Rentals™ provides professional tile removal equipment. Our tools are highly specialized and tailored specifically to tile removal. The type and quality of machinery we rent out is not available at most rental yards or big box rental area. No other company rents out a full tile removal package!',
     },
     {
          question:
               'Some of this demolition equipment seems complicated, do I need special training?',
          answer: 'Special training is not a requirement, the equipment is intuitive. We will go over a general explanation of how the tools work an general use of the equipment, there are a few important things to follow.',
     },
     {
          question: 'How do I book a rental?',
          answer: 'You can call us during normal business hours (9 am -5 pm) to set up your equipment rental. Alternatively, you can click on our schedule in the navigation menu above. The schedule is updated frequently, however, not a guarantee the date is available. Your requested date on the schedule is not confirmed until we have received your deposit and sent you your confirmation.',
     },
     {
          question: 'Where do I pick up the tile removal tools?',
          answer: 'In most cases, we will deliver the tools to your project. The majority of our rentals are “package” rentals, utilizing the full set of tile removal tooling.',
     },
     {
          question:
               'I have never removed tile before, how long will I need to rent the equipment?',
          answer: 'To give perspective, a fully trained professional tile removal company can rip out 850-1000+ sqft in a day. Depending on the package you rent, and how much help you can round up, an 850 sqft project may take you 2 days.',
     },
];

export default function Faq() {
     return (
          <>
               <PageTitle
                    firstWord='Frequently'
                    secondWord='Asked Questions'
                    breadcrumbLink='FAQ'
               />
               <div className='mx-auto max-w-7xl px-6 py-12 laptop:py-40 laptop:px-8'>
                    <div className='laptop:grid laptop:grid-cols-12 laptop:gap-8'>
                         <div className='laptop:col-span-5'>
                              <div className='sticky top-32'>
                                   <h1 className='origin-left text-dark uppercase font-bold text-[40px] leading-[2.5rem] mb-3 whitespace-nowrap'>
                                        <span className='block text-[30px] text-yellow'>
                                             You've got questions,
                                        </span>
                                        We've got answers.
                                   </h1>
                                   <p className='mt-8'>
                                        Can't find the answer you're looking
                                        for? Reach out to our{' '}
                                        <Link
                                             to='#'
                                             onClick={(e) => {
                                                  window.location.href =
                                                       'mailto:sales@ripoutrentals.com';
                                                  e.preventDefault();
                                             }}
                                             className='font-semibold text-yellow'
                                        >
                                             customer support
                                        </Link>{' '}
                                        team, 602-362-4644
                                   </p>
                              </div>
                         </div>
                         <div className='mt-10 laptop:col-span-7 laptop:mt-0'>
                              <dl className='space-y-10'>
                                   {faqs.map((faq) => (
                                        <div key={faq.question}>
                                             <dt className='text-2xl font-semibold leading-7 text-darker'>
                                                  {faq.question}
                                             </dt>
                                             <dd className='mt-2 leading-7 text-darker'>
                                                  {faq.answer}
                                             </dd>
                                        </div>
                                   ))}
                              </dl>
                         </div>
                    </div>
               </div>
          </>
     );
}
