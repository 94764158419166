import React from 'react';
import heroImage from '../assets/hero2.png';
import Button from '../components/ui/Button';

export default function Hero() {
     return (
          <div className='hero w-full relative tablet:bg-bottom'>
               <div className='container flex flex-col tablet:flex-row items-center z-30 relative'>
                    <div className='w-full tablet:w-[40%] laptop:w-1/2 laptop-l:w-[40%] pt-8 tablet:pb-12'>
                         <h1 className='scale-[.65] tablet:scale-75 laptop:scale-100 origin-left text-white text-[50px] laptop:text-[60px] leading-[3rem] mb-3 whitespace-nowrap'>
                              <span className='block text-[30px] text-yellow'>
                                   The Safe & Fast D.I.Y Solution
                              </span>
                              Dustless Tile Removal
                         </h1>
                         <h5 className='text-xl text-yellow pb-3'>
                              Full Package Equipment Rental{' '}
                              <span className='text-white'>
                                   for Dustless Tile Removal
                              </span>
                         </h5>
                         <Button
                              className='mt-2 tablet:mt-14 laptop:mt-20'
                              Href='/schedule'
                              Text='Book Now'
                              TextColor='text-white'
                         />
                    </div>
                    <img
                         className='w-full tablet:w-[60%] laptop:w-1/2 tablet-l:w-[60%] max-w-[500px] tablet:max-w-none mt-16 -mb-9'
                         src={heroImage}
                         alt='Floor Removal Equipment'
                    />
               </div>
               <div className='w-full h-full bg-gradient-to-b tablet:bg-gradient-to-r from-black/80 absolute top-0 left-0' />
          </div>
     );
}
