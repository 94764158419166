import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/ui/PageTitle';

const faqs = [
     {
          question:
            'Tile Breaker',
          answer: (<a href="/manuals/Bosch_11264evs.pdf" download>Bosch_11264evs.pdf</a>),
     },
     {
          question:
               'Vacuum',
          answer: (<a href="/manuals/Vacuum_S26.pdf" download>Vacuum_S26.pdf</a>),
     },
     {
          question: 'Grinder',
          answer: (<a href="/manuals/Metabo_W24-230_MVT.pdf" download>Metabo_W24-230_MVT.pdf</a>),
     },
     {
          question: 'Edging Tool',
          answer: (<a href="/manuals/Bosch_Bulldog_Extreme.pdf" download>Bosch_Bulldog_Extreme.pdf</a>),
     },
     
];

export default function Faq() {
     return (
          <>
               <PageTitle
                    firstWord='Frequently'
                    secondWord='Asked Questions'
                    breadcrumbLink='FAQ'
               />
               <div className='mx-auto max-w-7xl px-6 py-12 laptop:py-40 laptop:px-8'>
                    <div className='laptop:grid laptop:grid-cols-12 laptop:gap-8'>
                         <div className='laptop:col-span-5'>
                              <div className='sticky top-32'>
                                   <h1 className='origin-left text-dark uppercase font-bold text-[40px] leading-[2.5rem] mb-3 whitespace-nowrap'>
                                        <span className='block text-[30px] text-yellow'>
                                             Knowledge & Safety
                                        </span>
                                        Using The Equipment
                                   </h1>
                                   <p className='mt-8'>
                                        Download the users manuals as a .pdf.
                                        Can't find the answer you're looking
                                        for? Reach out to our{' '}
                                        <Link
                                             to='#'
                                             onClick={(e) => {
                                                  window.location.href =
                                                       'mailto:sales@ripoutrentals.com';
                                                  e.preventDefault();
                                             }}
                                             className='font-semibold text-yellow'
                                        >
                                             customer support
                                        </Link>{' '}
                                        team, 602-362-4644
                                   </p>
                              </div>
                         </div>
                         <div className='mt-10 laptop:col-span-7 laptop:mt-0'>
                              <dl className='space-y-10'>
                                   {faqs.map((faq) => (
                                        <div key={faq.question}>
                                             <dt className='text-2xl font-semibold leading-7 text-darker'>
                                                  {faq.question}
                                             </dt>
                                             <dd className='mt-2 leading-7 text-darker'>
                                                  {faq.answer}
                                             </dd>
                                        </div>
                                   ))}
                              </dl>
                         </div>
                    </div>
               </div>
          </>
     );
}
